export const stringifyMessage = (msg: unknown): string => (typeof msg === "string" ? msg : JSON.stringify(msg));

export const sanitizeHref = (currentUrl: string): string => {
  let sanitizedUrl = currentUrl;

  const absoluteUrl = new URL(currentUrl);
  const { search, hash } = absoluteUrl;

  if (search) {
    replaceSensitiveDataInSearch(absoluteUrl.searchParams);
    sanitizedUrl = absoluteUrl.href;
  }

  if (hash) {
    const { pathname, origin } = absoluteUrl;
    // moving the '#'from hash to base to detect search params correctly via URL API
    const url = hash.slice(1);
    const base = origin + pathname + search + hash.slice(0, 1);
    const relativeUrl = new URL(url, base);
    const relativeUrlSearch = relativeUrl.search;

    if (relativeUrlSearch) {
      replaceSensitiveDataInSearch(relativeUrl.searchParams);
      sanitizedUrl = sanitizedUrl.replace(relativeUrlSearch, relativeUrl.search);
    }
  }

  return sanitizedUrl;
};

const replaceSensitiveDataInSearch = (searchParams: URLSearchParams): void => {
  const sensitivePartialSearchKeys = ["token"];

  sensitivePartialSearchKeys.forEach((sensitiveKey) => {
    searchParams.forEach((value, key) => {
      if (key.toLocaleLowerCase().includes(sensitiveKey)) {
        searchParams.set(key, key);
      }
    });
  });
};
