import { HttpErrorResponse, HttpRequest } from "@angular/common/http";
import { ErrorEvent } from "reconnecting-websocket";
import { storage } from "@gtmhub/core/storage";
import { ILoggingConfig } from "@webapp/core/app-config/models/app-config.models";
import { globalRetries } from "@webapp/core/http/global-retries";
import { IRetryRequestConfig } from "@webapp/core/http/models/http.models";
import { IHttpRejection, ILoggable, LogLevel, LogSource } from "@webapp/core/logging/models/logging.models";
import { IStatsItem } from "@webapp/core/tracing/models/tracing.models";
import { sanitizeHref, stringifyMessage } from "../logging.utils";

export class LoggingService {
  constructor(
    private logging: ILoggingConfig,
    private src: LogSource
  ) {}

  public captureConsoleError(): void {
    // log window console error messages to fluentd
    const _onerror = window.onerror;
    window.onerror = (event: Event | string, source?: string, lineno?: number, colno?: number, error?: Error): void => {
      if (error) {
        this.logError(error);
      }

      if (_onerror && typeof _onerror === "function") {
        _onerror(event, source, lineno, colno, error);
      }
    };
  }

  public logMessage(message: unknown, opts: { level: LogLevel; async?: boolean }): void {
    if (!this.logging.enabled) {
      return;
    }

    const payload = {
      ...this.commonLogPayload(message, opts),
      url: sanitizeHref(window.location.href),
      error: false,
      console: true,
    };
    this.log(payload, { async: opts.async });
  }

  public logError(error: Error, opts: { async?: boolean } = {}): void {
    if (!this.logging.enabled) {
      return;
    }

    let loggableData = {};
    const loggable = error as unknown as ILoggable;
    if (loggable && "loggableData" in loggable) {
      loggableData = loggable.loggableData();
    }

    const payload = {
      ...loggableData,
      ...this.commonLogPayload(error.message, { level: "ERROR" }),
      error: true,
      console: true,
      stack: error ? error.stack : "null",
      name: error.name || "null",
      url: sanitizeHref(window.location.href),
    };
    this.log(payload, { async: opts.async });
  }

  public logSocketError(event: ErrorEvent, url: string): void {
    if (!this.logging.enabled) {
      return;
    }

    const message = event.error?.message || event.message;
    const payload = {
      ...this.commonLogPayload(message, { level: "ERROR" }),
      msg: stringifyMessage(event.error?.message) || stringifyMessage(event.message) || "null",
      stack: event.error?.stack || "null",
      name: event.error?.name || "null",
      ws: true,
      error: true,
      console: false,
      url,
    };
    this.log(payload);
  }

  public logHttpError(rejection: HttpErrorResponse, request: HttpRequest<unknown>): void {
    if (!this.logging.enabled) {
      return;
    }

    const config = <IRetryRequestConfig>(request || {});
    const headers = {};

    request.headers.keys().forEach((key) => {
      headers[key] = request.headers.get(key);
    });
    delete headers["Authorization"];

    const payload = this.getHttpErrorPayload({
      status: rejection.status,
      message: rejection.message,
      headers,
      config,
      xhrStatus: rejection.status.toString(),
    });
    this.log(payload);
  }

  public logHttpErrorLegacy(rejection: IHttpRejection): void {
    if (!this.logging.enabled) {
      return;
    }

    const config = <IRetryRequestConfig>(rejection.config || {});
    const headers = config.headers || {};
    delete headers["Authorization"];

    const payload = this.getHttpErrorPayload({
      status: rejection.status,
      message: stringifyMessage(rejection.data),
      headers,
      config,
      xhrStatus: rejection.xhrStatus,
    });
    this.log(payload);
  }

  private getHttpErrorPayload({
    status,
    message,
    config,
    headers,
    xhrStatus,
  }: {
    status: number;
    message: string;
    config: IRetryRequestConfig;
    headers: Record<string, never>;
    xhrStatus: string;
  }): Record<string, unknown> {
    return {
      ...this.commonLogPayload(message, { level: "ERROR" }),
      msg: message || "null",
      error: true,
      console: false,
      httpRequest: {
        method: config.method,
        headers,
        jaegerData: this.generateStatsItem(
          headers["jaeger-baggage"] || "sessionID=<unknown>",
          headers["jaeger-baggage-screen"] || "screenID=<unknown>, screenName=<unknown>",
          config
        ),
      },
      httpResponse: {
        statusCode: status,
        /* not sure if we need the next 2 lines anymore - retries are handled automatically  by http-error.interceptor*/
        retries: config.retries,
        globalRetries: globalRetries(),
        xhrStatus,
      },
      url: sanitizeHref(window.location.href),
      transactionId: headers["transaction-id"] || "Error: Add transactionId",
    };
  }

  private generateStatsItem(jaegerBaggage: string, jaegerBaggageScreen: string, config: IRetryRequestConfig): IStatsItem {
    const jaegerBaggageRecord = this.parseHeader(jaegerBaggage);
    const jaegerBaggageScreenRecord = this.parseHeader(jaegerBaggageScreen);

    return {
      sessionID: jaegerBaggageRecord.sessionID || "null",
      screenID: jaegerBaggageScreenRecord.screenID || "null",
      screenName: jaegerBaggageScreenRecord.screenName || "null",
      actionID: jaegerBaggageRecord.actionID || "null",
      actionName: jaegerBaggageRecord.actionName || "null",
      url: config.url,
      method: config.method,
    };
  }

  // Parses a header value in the form of
  // key1=value1, key2=value2,key3=value3
  // into an object
  // { key1: value1, key2: value2, key3: value3 }
  private parseHeader(value: string): Record<string, string> {
    return value
      .split(/,\s*/g)
      .map((x) => {
        const y = x.split("=");
        return { [y[0]]: y[1] };
      })
      .reduce((result, x) => ({ ...result, ...x }), {});
  }

  private log(payload: unknown, opts: { async?: boolean } = {}): void {
    try {
      const async = opts.async !== false;
      const xmlHttp = new XMLHttpRequest();
      xmlHttp.open("POST", this.logging.endpoint, async);
      xmlHttp.setRequestHeader("Content-Type", "application/json");
      xmlHttp.send(JSON.stringify(payload));
    } catch (ex) {
      if (window && window.console && typeof window.console.log === "function") {
        console.warn("Failed to log to fluentd because of this exception:\n" + ex);
        console.warn("Failed log data:", payload);
      }
    }
  }

  private commonLogPayload(message: unknown, opts: { level: LogLevel }): Record<string, unknown> {
    return {
      accountId: storage.get("accountId") || "null",
      userId: storage.get("userId") || "null",
      userAgent: window.navigator ? window.navigator.userAgent : "null",
      tags: { ...this.logging.tags, src: this.src },
      level: opts.level,
      msg: stringifyMessage(message) || "null",
    };
  }
}
