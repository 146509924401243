import { ILoggingConfig } from "@gtmhub/env";
import { stringifyMessage } from "@webapp/core/logging/logging.utils";
import { LoggingService } from "@webapp/core/logging/services/logging.service";
import { IHttpRejection } from "./core/logging";

interface IHttpErrorData {
  jqXHR: IJqXHRDetails;
  textStatus: string;
}

interface IJqXHRDetails {
  status: number;
  responseText: string;
}

let loggingService: LoggingService;

export function initLogging(loggingConfig: ILoggingConfig): void {
  loggingService = new LoggingService(loggingConfig, "js");
  loggingService.captureConsoleError();
}

export function logHttpError(requestConfig: JQuery.AjaxSettings<unknown>, errorData: IHttpErrorData): void {
  if (loggingService) {
    loggingService.logHttpErrorLegacy(generateRejectionData(requestConfig, errorData));
  } else {
    console.warn("You must initialize the logging by calling `initLogging` first");
  }
}

export function logCustomError(message: unknown, opts: { async?: boolean } = {}): void {
  console.error(message);
  if (loggingService) {
    loggingService.logError(new Error(stringifyMessage(message)), { async: opts.async });
  } else {
    console.warn("You must initialize the logging by calling `initLogging` first");
  }
}

const generateRejectionData = (requestConfig: JQuery.AjaxSettings<unknown>, errorData: IHttpErrorData): IHttpRejection => ({
  config: {
    headers: requestConfig.headers,
    method: requestConfig.type,
    url: requestConfig.url,
  },
  status: errorData.jqXHR.status,
  data: errorData.jqXHR.responseText,
  xhrStatus: errorData.textStatus,
});

const module = { initLogging, logHttpError, logCustomError };

declare global {
  interface Window {
    ghLogging: typeof module;
  }
}

window.ghLogging = module;
